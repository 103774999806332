<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('core.emailtemplates')">
    <CCol cols="12" lg="12" md="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="5" xl="5" class="pt-0 pb-0 text-left">
              {{ $t("core.Email_templates") }}
            </CCol>
            <CCol cols="7" xl="7" class="pt-0 pb-0 text-xl-right">          
              <div class="ml-2 mt-1 mt-xl-0 d-inline-block align-top">
                <CButton class="mr-0 link" color="primary" @click="showNewTemplate();">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{ $t('common.Add_mail_template') }}</span>
                </CButton>
              </div>
            </CCol> 
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow>
            <CCol cols="12" lg="12" class="pb-0">
              <b-table class="data_table" ref="emailTemplateTable" :data="templates" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
                <template slot-scope="props">
                  <b-table-column field="name" :label="$t('common.Name')" :searchable="searchEnabled" width="20%">
                    <span>{{props.row.name}}</span>
                    <span class="mt-1 d-block" style="font-size: 0.8em;">{{props.row.created_by_name}} | {{props.row.created | moment("YYYY-MM-DD HH:mm")}}</span>
                  </b-table-column>
                  <b-table-column field="description" :label="$t('common.Description')" width="30%" :searchable="searchEnabled">
                    <span>{{props.row.description ? props.row.description : '-'}}</span>
                  </b-table-column>
                  <b-table-column field="updated" :label="$t('common.Last_update')" :searchable="searchEnabled">
                    <span>{{props.row.updated_by_name }} @ {{props.row.updated | moment("YYYY-MM-DD HH:mm")}}</span>
                  </b-table-column>              
                  <b-table-column field="actions">
                    <div class="d-flex justify-content-lg-end">
                      <CButton class="m-0 d-inline-block" color="primary" @click="showTemplateDetails(props.row.platform_event_email_template_id_external)">
                        <i class="fas fa-pen"/>
                      </CButton>
                    </div>
                  </b-table-column>  
                </template>
                <template slot="empty">
                  <div class="text-center">
                    <loadingSpinner mode="auto" v-if="!dataLoaded" :content="null"/>
                    <span v-else>{{ $t('common.No_mail_templates_found') }}</span>
                  </div>                
                </template>                              
              </b-table>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';

export default {
  name: 'Campaigns',
  components: {
    loadingSpinner,
    noPermission
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      templates: [],
      dataLoaded: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      searchEnabled: true
    }
  },
  methods: {
    getMailTemplates () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/email/templates/overview')
      .then(res => {      
        this.templates = res.data.data;
        // Check if the pagination should be activated
        (this.templates.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        // Update the dataLoaded value
        this.dataLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    showNewTemplate() {
      this.$router.push({path: '/core/email/template/new'});
    },    
    showTemplateDetails(id) {
      this.$router.push({path: `/core/email/template/${id.toString()}`});
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function() {
    this.getPlatformPermissions();
    this.getMailTemplates();
  }
}
</script>